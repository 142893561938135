export const FEATURE_FLAGS = [
  {
    name: "new-tender-2",
    local: "control", // control, variant1, variant2
  },
  {
    name: "listpage-visual-hierarchy-header",
    local: "test",
  },
  {
    name: "enable-email-send",
    local: true,
  },
];
