import { useContext } from "@nuxtjs/composition-api";

export function useMiniMarketActions() {
  const { $mutate, $query } = useContext();

  async function getMiniMarketPositionHistoriesDataTable(params) {
    try {
      const GET_MINI_MARKET_POSITIONS = await import(
        "./miniMarketPositionHistoriesDataTable.graphql"
      );
      const { miniMarketPositionHistoriesDataTable } = await $query(
        GET_MINI_MARKET_POSITIONS,
        params
      );
      return miniMarketPositionHistoriesDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function shufflePeopleInMiniMarket(id) {
    try {
      const SHUFFLE_PEOPLE_IN_MINI_MARKET = await import(
        "./shufflePeopleInMiniMarket.graphql"
      );
      await $mutate(
        SHUFFLE_PEOPLE_IN_MINI_MARKET,
        { id },
        { showErrorToast: true, showSuccessToast: true }
      );
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getSubcountyStatInfos(professionId) {
    try {
      const GET_SUBCOUNTY_STATISTICS = await import(
        "./getSubcountyStatistics.graphql"
      );
      const { subcounties } = await $query(GET_SUBCOUNTY_STATISTICS, {
        professionId,
      });
      return subcounties;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function saveMiniMarket(submitData) {
    try {
      if (submitData.id !== null) {
        const UPDATE_MINI_MARKET = await import("./updateMiniMarket.graphql");
        return await $mutate(UPDATE_MINI_MARKET, submitData);
      } else {
        const CREATE_MINI_MARKET = await import("./createMiniMarket.graphql");
        return await $mutate(CREATE_MINI_MARKET, submitData);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function deleteMiniMarketById(id) {
    try {
      const DELETE_MINI_MARKET = await import("./deleteMiniMarketById.graphql");
      await $mutate(DELETE_MINI_MARKET, { id });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function convertPeopleToMiniMarket(miniMarketId) {
    try {
      const CONVERT_PEOPLE_TO_MINI_MARKET = await import("./convertPeopleToMiniMarket.graphql");
      await $mutate(CONVERT_PEOPLE_TO_MINI_MARKET, { miniMarketId });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getMiniMarketById(id) {
    try {
      const GET_MINI_MARKET = await import("./getMiniMarketById.graphql");
      const { miniMarketById } = await $query(GET_MINI_MARKET, { id });
      return miniMarketById;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getMiniMarkets(params) {
    try {
      const GET_MINI_MARKETS = await import("./miniMarketsDataTable.graphql");
      const { miniMarketsDataTable } = await $query(GET_MINI_MARKETS, params, {
        showErrorToast: true,
      });
      return miniMarketsDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  return {
    getSubcountyStatInfos,
    saveMiniMarket,
    deleteMiniMarketById,
    getMiniMarketById,
    getMiniMarkets,
    getMiniMarketPositionHistoriesDataTable,
    shufflePeopleInMiniMarket,
    convertPeopleToMiniMarket,
  };
}
