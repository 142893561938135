import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration, reportingObserverIntegration } from '~@sentry/integrations'
import { init, browserTracingIntegration } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = []

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002Fd003190d507345da8127e9edbe9e7360@sentry.io\u002F1847962",
    environment:"production",
    release:"nuxt@10.34.1",
    sampleRate:0.1,
    tracesSampleRate:0.001,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
    reportingObserverIntegration({ types:["crash"] }),
  ]

  resolvedIntegrations.push(browserTracingIntegration({
    router: ctx.app.router,
    ...{"routeLabel":"name"},
    ...{},
  }))
  merge(config, {"trackComponents":false}, {"tracesSampleRate":0.001})

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
