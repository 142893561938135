<template>
  <JoszakiAutocomplete
    ref="cityAutocomplete"
    v-model="cityFilter"
    :icon="icon"
    :items="filteredCities"
    v-bind="$attrs"
    :keep-first="keepFirst"
    :placeholder="value?.name ?? placeholder ?? ''"
    :label="showLabel ? label ?? $t('common.city') : null"
    @focus="onFocus"
    @blur="onBlur"
    @select="select"
  >
    <template #empty>
      {{ $t("cityAutocomplete.empty") }}
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import { useCitySearch } from "~/composables/useCitySearch";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    keepFirst: {
      type: Boolean,
      default: true,
    },
    filterCapitol: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "map-marker-alt",
    },
    placeholder: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    typingTrackerEvent: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const citySearch = ref("");
    const openOnFocus = ref(true);
    return {
      citySearch,
      openOnFocus,
      ...useCitySearch(props.filterCapitol),
    };
  },
  computed: {
    isClientReady() {
      return process.client;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.cityFilter = "";
          return;
        }
        if (newValue.name !== this.cityFilter) {
          this.cityFilter = newValue.name;
        }
      },
    },
    cityFilter(...args) {
      if (this.typingTrackerEvent) {
        this.debouncedTrackTyping(...args);
      }
    },
  },
  async mounted() {
    await this.loadCities();
    if (this.autofocus) {
      this.focusInput();
    }

    if (this.typingTrackerEvent) {
      this.debouncedTrackTyping = debounce((newValue, _oldValue) => {
        this.$trackers.trackEvent({
          title: this.typingTrackerEvent,
          data: { value: newValue },
        });
      }, 500);
    }
  },
  methods: {
    focusInput() {
      try {
        this.$refs.cityAutocomplete.focusInput();
      } catch (error) {
        console.info("Cant auto focus cityAutocomplete input");
      }
    },
    onFocus() {
      this.openOnFocus = false;
      this.cityFilter = this.citySearch;
      this.$emit("focus");
    },
    onBlur() {
      this.citySearch = this.cityFilter;
      this.cityFilter = this.value?.name ?? "";
      this.$emit("blur");
    },
    select(city) {
      this.citySearch = "";
      if (city) {
        this.$emit("select", city);
      } else {
        this.blur();
      }
    },
    blur() {
      this.openOnFocus = !this.openOnFocus;
      this.$refs.cityAutocomplete.isActive =
        !this.$refs.cityAutocomplete.isActive;
    },
  },
};
</script>
